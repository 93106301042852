import React from 'react'
import "./SetConsentModal.css"
import {equals, mergeAll, values, compose} from 'ramda';
import { isBrowser } from '../../../../utils/zeotap';
import { tooltipCss, tooltipTextCSS } from '../../../../utils/const';
import { GrCircleInformation } from 'react-icons/gr';

export default class SetConsentModal extends React.Component {
  constructor(props){
    super(props);
    this.state={
        hideGranularConsent: true,
        granularConsent: {
            purpose: false,
            track: false,
            cookieSync: false
        },
        expiry: '365',
        brandConsent: {},
        agreeAllClicked: false,
        disagreeAllClicked: false,
        granularConsentClicked: false
    }
  }
  agreeAll = () => {
    this.setState({
            hideGranularConsent: true,
            granularConsent : {
                purpose: true,
                track: true,
                cookieSync: true
            },
            agreeAllClicked: true,
            disagreeAllClicked: false,
            granularConsentClicked: false
    });
  }

  disagreeAll = () =>{
    this.setState({
        hideGranularConsent: true,
        granularConsent : {
            purpose: false,
            track: false,
            cookieSync: false
        },
        agreeAllClicked: false,
        disagreeAllClicked: true,
        granularConsentClicked: false
    });
  }

  setGranularConsent = () =>{
    this.setState({
        hideGranularConsent: false,
        agreeAllClicked: false,
        disagreeAllClicked: false,
        granularConsentClicked: true
    });
  }

  onGranularConsentChange = (event) => {
    const { name, checked } = event.target;
    this.setState({granularConsent : {...this.state.granularConsent, [name] : checked}});
  }

  onExpiryChange = (event) => {
    this.setState({expiry: event.target.value});
  }

  onBrandConsentLabelChange = (event, i) => {
    const val = Object.values(this.state.brandConsent[i])[0]
    this.setState({brandConsent: {...this.state.brandConsent, [i] : { [event.target.value] : val }}})
  }

  onBrandConsentValueChange = (event, i) => {
    const key = Object.keys(this.state.brandConsent[i])[0];
    this.setState({brandConsent: {...this.state.brandConsent, [i] : { [key] : event.target.checked }}})
  }

  submitConsent = () => {
      const consent = this.state.agreeAllClicked ? true : this.state.disagreeAllClicked ? false : this.state.granularConsent;
      console.log('set consent ', consent, +this.state.expiry, compose(mergeAll, values)(this.state.brandConsent));
      isBrowser() && window.zeotap.setConsent(consent, +this.state.expiry, compose(mergeAll, values)(this.state.brandConsent))
      this.props.onClose();
  }

  addBrandConsent = () => {
      const brandConsentLength = Object.keys(this.state.brandConsent).length;
      this.setState({brandConsent: {...this.state.brandConsent, [brandConsentLength] : { purpose_x : false }}})
  }

  isAnyBrandConsentEmpty = () => {
    const brandConsentLength = Object.keys(this.state.brandConsent).length;
      return brandConsentLength > 0 && equals(this.state.brandConsent[brandConsentLength - 1], { purpose_x : false });
  }

  isConsentSelectionNotDone = () => {
      return !(this.state.agreeAllClicked || this.state.disagreeAllClicked || this.state.granularConsentClicked);
  }
  
  render() {
    return ( 
        <div className="consent-modal-container"> 
            <div className="text-lg font-bold mb-2 flex justify-center">We value your privacy</div>
            <div className="text-xs mb-2 flex justify-center">
                Our SDK performs the task of identifying users, capturing events and cookieSyncing.
                Agree All/Disagree All, shall activate/deactivate all these SDK functions.
                To provide granular consent to these SDK purposes select 'More Options'.
            </div>
            <div className="pl-4">
                <div className="flex justify-center">
                    <span className="btn" onClick={this.agreeAll}>Agree All</span>
                    <span className="btn" onClick={this.disagreeAll}>Disagree All</span>
                    <span className="btn" onClick={this.setGranularConsent}>More Options</span>
                </div>
                {
                // !this.state.hideGranularConsent ? (
                    <div className={ !this.state.hideGranularConsent ? "p-2 border" : "p-2 border disable-btn"}>
                    {
                        Object.entries(this.state.granularConsent).map(([k, v], i) => (
                            <div className="flex col-gap-2 items-center my-2" key={i}>
                                <input aria-label={k} onChange={this.onGranularConsentChange} value={v} name={k} checked={v}
                                    className="cursor-pointer w-5 h-5" id={k} type="checkbox" disabled={this.state.hideGranularConsent}/>
                                <label className="block text-gray-700 text-sm font-bold capitalize" htmlFor={k}>{k}</label>
                            </div>
                        ))
                    }
                    </div>
                    // ) : null
                }
                <div className="p-2 border flex items-center">
                    <div className="text-sm flex items-center">
                        <label htmlFor="expiry">Expiry (no of days)</label>
                        <div className={"tooltip "+ tooltipCss}><GrCircleInformation />
                                <span className={"tooltiptext "+ tooltipTextCSS}>Set expiry in number of days. This would refresh the consent set after the set days.</span>
                        </div>
                    </div>
                    <input type="number" id="expiry" className="shadow appearance-none border rounded w-1/3 py-2 px-3 text-gray-700 ml-4 leading-tight focus:outline-none focus:shadow-outline" 
                    onChange={this.onExpiryChange} value={this.state.expiry} name="expiry" pattern="^[0-9]+$"></input>
                </div>
                <div className="p-2 border">
                    <div className="font-bold text-sm">Provide collected brand consent, if any.</div>
                    { Object.values(this.state.brandConsent).length ? (
                        <div className="text-sm flex col-gap-4 items-center m-2">
                            <span>Brand Consent Label</span>
                            <span>Value (T/F)</span>
                        </div>) : null
                    }
                    <div className="max-h-40 overflow-scroll">
                    { Object.values(this.state.brandConsent).map((v, i) => (
                        <div className="flex col-gap-4 items-center my-2" key={i}>
                            <input type="text" className="shadow appearance-none border rounded w-1/3 py-2 px-3 text-gray-700 ml-2 leading-tight focus:outline-none focus:shadow-outline" 
                                onChange={(e) => this.onBrandConsentLabelChange(e,i)} value={Object.entries(v)[0][0]} name={i}></input>
                            <input onChange={(e) => this.onBrandConsentValueChange(e,i)} value={Object.entries(v)[0][1]} name={i} checked={Object.entries(v)[0][1]}
                                className="cursor-pointer w-5 h-5" type="checkbox" />
                        </div>
                    ))
                    }</div>
                    <div className={ this.isAnyBrandConsentEmpty() ? 'disable-btn btn' : 'btn'} onClick={this.addBrandConsent}>Add Brand Consent</div>
                </div>
                <div className={ this.isConsentSelectionNotDone() ? 'disable-btn btn flex justify-self-center' : 'btn flex justify-self-center'} onClick={this.submitConsent}>Save & Exit</div>
            </div>
        </div>
     )
  }
}
