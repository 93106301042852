import React from 'react'
import { SiteContext } from '../context/mainContext'
import { navigate } from "gatsby"
import { setEventProperties, setUserProperties, unsetUserIdentities } from '../../utils/zeotap'
import { GrLogin, GrLogout } from 'react-icons/gr'
import ConditionalLink from './ConditionalLink'
import { getSiteConfigField } from '../../utils/helpers';

class LogLink extends React.Component {

    logout = () => {
        setEventProperties("signedOut");
        try{
          unsetUserIdentities();   
          setUserProperties(null); 
        }
        catch(e){
          console.log(e.message)
        }
        navigate('/login')    
        }

  render() {
    let { context: { signedIn , signOut } } = this.props
    return (
      <div>
        <div className="fixed top-49 right-20 desktop:right-flexiblemargin z-50 mr-10">
          <div className="flex flex-1 justify-end relative">
          {!signedIn ? (<ConditionalLink className="mr-8" redirectUrl="/login/" onClick={()=>setEventProperties(`loginClicked`)} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
                          <GrLogin />
                        </ConditionalLink>) 
                        : (<ConditionalLink className="mr-8" redirectUrl="/login/" onClick={()=>{signOut();this.logout()}} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' } >
                          <GrLogout />
                        </ConditionalLink>)}
          </div>
        </div>
      </div>
    )
  }
}


function LogLinkWithContext(props) {
  return (
    <SiteContext.Consumer>
      {
        context => <LogLink {...props} context={context} />
      }
    </SiteContext.Consumer>
  )
}


export default LogLinkWithContext