import React from 'react'
import { SiteContext } from '../context/mainContext'
import { FaShoppingCart, FaCircle } from 'react-icons/fa';
import { colors } from '../theme'
import { setEventProperties } from '../../utils/zeotap'
import { getSiteConfigField } from '../../utils/helpers';
import ConditionalLink from './ConditionalLink'
const { secondary } = colors

class CartLink extends React.Component {
  render() {
    let { context: { numberOfItemsInCart } = { numberOfItemsInCart: 0 } } = this.props
    return (
      <div>
        <div className="fixed top-49 right-20 desktop:right-flexiblemargin z-50 mr-32">
          <div className="flex flex-1 justify-end relative">
            <ConditionalLink redirectUrl="/cart/" onClick={() => setEventProperties('viewCart')}  isMultiPageSite= {getSiteConfigField('multiPageSite') === 'true' }>
              <FaShoppingCart />
            </ConditionalLink>
            {numberOfItemsInCart > Number(0) && (
              <div>
                <FaCircle color={secondary} size={12} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}


function CartLinkWithContext(props) {
  return (
    <SiteContext.Consumer>
      {
        context => <CartLink {...props} context={context} />
      }
    </SiteContext.Consumer>
  )
}


export default CartLinkWithContext