import React from 'react'
import Image from '../Image'

const Showcase = ({ imageSrc }) => {
  return (
    <div className="z-10 h-full">
      <Image src={imageSrc}  alt="Showcase item" />
    </div>
  )
}

export default Showcase