import React from 'react'
import Image from '../Image'
import { setEventProperties } from '../../../utils/zeotap'
import ConditionalLink from '../ConditionalLink'
import { getSiteConfigField } from '../../../utils/helpers'

const DisplayMedium = ({ imageSrc, title, subtitle, link, id}) => {
  return (
    <div className="lg:w-flex-half
    mb-4 lg:mb-0
    bg-light p-8 pb-0 hover:bg-light-200">
      <ConditionalLink redirectUrl={`/${link}`} onClick={()=>setEventProperties('selectCategory',{categoryName: title})} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
        <div className="flex flex-column justify-center items-center">
          <Image src={imageSrc} alt={title} className="w-3/5 showcase" />
        </div>
        <div className="">
          <p className="text-3xl font-semibold mb-1">{title}</p>
          <p className="text-xs text-gray-700">{subtitle}</p>
        </div>
      </ConditionalLink>
    </div>
  )
}

export default DisplayMedium;